/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "usehooks-ts";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import {
  createEventCard,
  getAllAddonIcons,
  getCollectionById,
  updateCollectionCategory,
} from "../../../api/event";
import ImageUpload from "../../../components/create_event/ImageUpload";
import ItemDetail from "../../../components/create_event/ItemDetail";
import ItemLinks from "../../../components/create_event/ItemLinks";
import ItemAddons from "../../../components/create_event/ItemAddons";
import SplitPayment from "../../../components/create_event/SplitPayment";
import ItemRoyalty from "../../../components/create_event/ItemRoyalty";
import { BKS_wallet } from "../../../utils/nft_contract";
import {
  USDTPayment_mainnet,
  USDTPayment_ABI,
} from "../../../utils/payment_contract";
import client from "../../../utils/ipfs_client";
import {
  ICollection,
  IAddon,
  IEventCard,
  IPayfee,
  IRoyality,
} from "../../../utils/interface";
import { isWalletValid } from "../../../utils";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
import styles from "./index.module.css";

const PageEventCardCreate = () => {
  const { userInfo } = useUserContext();
  const { id } = useParams();
  const [collection, setCollection] = useState<ICollection>();

  const [largeFile, setLargeFile] = useState<File>();
  const [largeFileSize, setLargeFileSize] = useState({ width: 0, height: 0 });
  const [nftFile, setNftFile] = useState<File>();
  const [floorMapFile, setFloorMapFile] = useState<File>();
  const [smallFile, setSmallFile] = useState<File>();
  const [largeImagePreview, setLargeImagePreview] = useState("");
  const [smallImagePreview, setSmallImagePreview] = useState("");
  const [isFloorMapImageChanged, setIsFloorMapImageChanged] = useState(false);
  const [nftImagePreview, setNftImagePreview] = useState("");
  const [floorMapImagePreview, setFloorMapImagePreview] = useState("");

  const [multiImages, setMultiImages] = useState<File[]>([]);
  const [multiImagesPreview, setMultiImagesPreview] = useState<string[]>([]);

  const [multiVideo, setMultiVideo] = useState<File>();
  const [multiVideoPreview, setMultiVideoPreview] = useState("");

  const [benefitImage, setBenefitImage] = useState<File>();
  const [benefitImagePreview, setBenefitImagePreview] = useState("");

  const [benefitImages, setBenefitImages] = useState<(File | undefined)[]>([]);

  const [greenPass, setGreenPass] = useState(0);
  const [fixePeriod, setFixedPeriod] = useState(0);
  const [taxInclude, setTaxInclude] = useState(0);
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const { setLoading, settings, addToast } = useAppContext();
  const navigate = useNavigate();

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const { t } = useTranslation();

  const [values, setValues] = useState({
    name: "",
    location: "",
    price: "0",
    tax: 0,
    currency: "USD",
    period: "",
    period_unit: "Year(s)",
    date: "",
    end_date: "",
    venue_description: "",
    terms_conditions: "",
    description: "",
    facebook: "",
    twitter: "",
    instagram: "",
    tiktok: "",
    telegram: "",
    discord: "",
    email: "",
    website: "",
    wallet: "bsc",
    category: "",
    collection: `${id}`,
    total_tickets: "",
    owner_wallet: BKS_wallet,
    owner_near_wallet: "",
    owner_account: "",
    scan: 1,
    stop_time: "",
    time_zone: 0,
  });

  const initialValidation = {
    picture_large: "",
    picture_nft: "",
    picture_small: "",
    picture_floormap: "",
    name: "",
    location: "",
    price: "",
    tax: "",
    date: "",
    end_date: "",
    venue_description: "",
    description: "",
    terms_conditions: "",
    category: "",
    collection: "",
    total_tickets: "",
    owner_wallet: "",
    owner_near_wallet: "",
    owner_account: "",
    scan: "",
  };

  const [validations, setValidations] = useState(initialValidation);

  const [addons, setAddons] = useState<IAddon[]>([] as IAddon[]);
  const [addonIcons, setAddonIcons] = useState<string[]>([] as string[]);

  // const [royalties, setRoyalties] = useState<IRoyality[]>([
  //   {
  //     chain: values.wallet,
  //     wallet: collection?.royalty_wallet,
  //     account: "royaltyAccount",
  //     fee: "10",
  //   },
  // ] as IRoyality[]);

  const royalties = useMemo(() => {
    return [
      {
        chain: values.wallet,
        wallet: collection?.royalty_wallet,
        account: "royaltyAccount",
        fee: "10",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const [payees, setPayees] = useState<IPayfee[]>([] as IPayfee[]);

  const add_Addons = (
    addonName: string,
    addonPrice: string,
    addonDescription: string,
    addonIcon: string
  ) => {
    if (addons.length < 8) {
      setBenefitImages([...benefitImages, benefitImage]);
      setAddons([
        ...addons,
        {
          name: addonName,
          description: addonDescription,
          price: addonPrice,
          icon: addonIcon,
        },
      ]);
      setBenefitImage(undefined);
      setBenefitImagePreview("");
    }
  };
  const remove_Addon = (index: number) => {
    setAddons(addons.slice(0, index).concat(addons.slice(index + 1)));
    setBenefitImages(
      benefitImages.slice(0, index).concat(benefitImages.slice(index + 1))
    );
  };

  const add_Payee = (payeeWallet: string, payeeFee: string) => {
    if (payees.length > 10) return;
    setPayees([
      ...payees,
      {
        wallet: payeeWallet,
        account: "payeeAccount",
        fee: payeeFee,
      },
    ]);
  };
  const remove_Payee = (index: number) => {
    setPayees(payees.slice(0, index).concat(payees.slice(index + 1)));
  };

  const add_Royalty = (royaltyWallet: string, royaltyFee: string) => {
    // if (royalties.length > 10) return;
    // setRoyalties([
    //   ...royalties,
    //   {
    //     chain: values.wallet,
    //     wallet: royaltyWallet,
    //     account: "royaltyAccount",
    //     fee: royaltyFee,
    //   },
    // ]);
  };
  const remove_Royalty = (index: number) => {
    // setRoyalties(royalties.slice(0, index).concat(royalties.slice(index + 1)));
  };

  useEffect(() => {
    setLoading(true);
    getCollectionById(id).then((res) => {
      if (res.success) {
        setCollection(res.collection);
      }
      setLoading(false);
    });
    getAllAddonIcons().then((res) => {
      if (res.success) {
        setAddonIcons(res.addonicons);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkvalidations = () => {
    if (values.category === "") {
      setValidations({ ...initialValidation, category: "has-empty" });
      return false;
    } else if (!largeFile) {
      setValidations({ ...initialValidation, picture_large: "has-empty" });
      return false;
    } else if (!smallFile) {
      setValidations({ ...initialValidation, picture_small: "has-empty" });
      return false;
    } else if (!isSmallFileValid()) {
      setValidations({ ...initialValidation, picture_small: "has-invalid" });
      return false;
    } else if (!nftFile) {
      setValidations({ ...initialValidation, picture_nft: "has-empty" });
      return false;
    } else if (values.name === "") {
      setValidations({ ...initialValidation, name: "has-empty" });
      return false;
    } else if (values.category !== "Category2" && values.location === "") {
      setValidations({ ...initialValidation, location: "has-empty" });
      return false;
    } else if (!isPriceValid()) {
      setValidations({ ...initialValidation, price: "has-empty" });
      return false;
    } else if (values.tax >= 100 || values.tax < 0) {
      setValidations({ ...initialValidation, tax: "has-error" });
      return false;
    } else if (values.category !== "Category2" && !values.date) {
      setValidations({ ...initialValidation, date: "has-empty" });
      return false;
    } else if (values.category === "Category3" && !values.end_date) {
      setValidations({ ...initialValidation, end_date: "has-empty" });
      return false;
    } else if (
      values.category !== "Category2" &&
      convertHtmlToString(values.venue_description) === ""
    ) {
      setValidations({ ...initialValidation, venue_description: "has-empty" });
      return false;
    } else if (
      convertHtmlToString(values.description) === "" &&
      values.category === "Category2"
    ) {
      setValidations({ ...initialValidation, description: "has-empty" });
      return false;
    } else if (convertHtmlToString(values.terms_conditions) === "") {
      setValidations({ ...initialValidation, terms_conditions: "has-empty" });
      return false;
    } else if (values.collection === "") {
      setValidations({ ...initialValidation, collection: "has-empty" });
      return false;
    } else if (!isWalletValid(values.owner_wallet)) {
      setValidations({ ...initialValidation, owner_wallet: "has-empty" });
      return false;
    } else if (!values.total_tickets) {
      setValidations({ ...initialValidation, total_tickets: "has-empty" });
      return false;
    } else if (values.scan < 0) {
      setValidations({ ...initialValidation, scan: "has-error" });
      return false;
    } else if (values.tax < 0) {
      setValidations({ ...initialValidation, tax: "has-error" });
      return false;
    } else if (!isPayeeValid()) {
      addToast(
        "Payee fee should be less than 100% and wallet addresses should be valid",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      return false;
    } else if (!isRoyaltyValid()) {
      addToast(
        "Royalty fee should be less than 10% and wallet addresses should be valid",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      return false;
    } else {
      setValidations({ ...initialValidation });
    }

    return true;
  };

  const isPriceValid = () => {
    if (
      values.category !== "Category2" &&
      (!values.price ||
        isNaN(Number(values.price)) ||
        Number(values.price) <= 0)
    ) {
      return false;
    }
    return true;
  };

  const isPayeeValid = () => {
    const total = 110;
    let _payees = payees;

    let subTotal = 0;
    _payees.map((item: IPayfee) => {
      if (!isWalletValid(item.wallet)) subTotal = total + 1;
      else subTotal += Number(item.fee);
      return 0;
    });

    if (total < subTotal) {
      return false;
    } else return true;
  };

  const isRoyaltyValid = () => {
    // const maxRolyalty = settings.find(
    //   (item: any) => item.key === "maxRoyaltyFee"
    // );
    // const total = maxRolyalty ? maxRolyalty.value : 10;
    // let _royalties = royalties;

    // let subTotal = 0;
    // _royalties.map((item: any) => {
    //   if (!isWalletValid(item.wallet)) subTotal = total + 1;
    //   else subTotal += Number(item.fee);
    //   return 0;
    // });

    // if (total < subTotal) {
    //   return false;
    // } else return true;
    return true;
  };

  const largeImageLoaded = (target: any) => {
    setLargeFileSize({
      width: target.target.naturalWidth,
      height: target.target.naturalHeight,
    });
  };

  const isLargeFileValid = () => {
    if (largeFile?.type === "image/gif" || largeFile?.type === "video/mp4")
      return true;
    else if (largeFileSize.width < 1900 || largeFileSize.width > 1950)
      return false;
    else if (largeFileSize.height < 1050 || largeFileSize.height > 1100)
      return false;
    else return true;
  };

  const isSmallFileValid = () => {
    // if (smallFile?.type === "image/gif" && smallFile?.size < 6291110) {
    //   return true;
    // } else if (smallFile?.type && smallFile?.size < 521588) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  };

  const toggleGreenPass = () => {
    if (greenPass) {
      setGreenPass(0);
    } else {
      setGreenPass(1);
    }
  };

  const toggleFixedPeriod = () => {
    if (fixePeriod) {
      setFixedPeriod(0);
    } else {
      setFixedPeriod(1);
    }
  };

  const toggleTaxInclude = () => {
    if (taxInclude) {
      setTaxInclude(0);
    } else {
      setTaxInclude(1);
    }
  };

  const largeImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setLargeFile(f);
          setLargeImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }
      setValidations({ ...initialValidation, picture_large: "" });
    }
  };

  const benefitImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setBenefitImage(f);
          setBenefitImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }
    }
  };

  const multiVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setMultiVideo(f);
          setMultiVideoPreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }
    }
  };

  const multiImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (multiImages.length < 4) {
      if (e.target.files && e.target.files.length) {
        const reader = new FileReader();
        const f = e.target.files[0];
        if (reader !== undefined && f !== undefined) {
          reader.onloadend = () => {
            let multiFiles = multiImages;
            multiFiles.push(f);
            setMultiImages([...multiFiles]);
            let multiPreviews = multiImagesPreview;
            multiPreviews.push(reader.result as string);
            setMultiImagesPreview([...multiPreviews]);
          };
          reader.readAsDataURL(f);
        }
      }
    }
  };

  const multiImageDelete = (index: number) => {
    let multiFiles = multiImages;
    let multiPreviews = multiImagesPreview;
    multiFiles.splice(index, 1);
    multiImagesPreview.splice(index, 1);
    setMultiImages([...multiFiles]);
    setMultiImagesPreview([...multiPreviews]);
  };

  const imageDelete = (type: string) => {
    switch (type) {
      case "large":
        setLargeFile(undefined);
        setLargeImagePreview("");
        break;
      case "small":
        setSmallFile(undefined);
        setSmallImagePreview("");
        break;
      case "nft":
        setNftFile(undefined);
        setNftImagePreview("");
        break;
      case "floorMap":
        setFloorMapFile(undefined);
        setFloorMapImagePreview("");
        break;
      case "multiVideo":
        setMultiVideo(undefined);
        setMultiVideoPreview("");
        break;
      case "benefitImage":
        setBenefitImage(undefined);
        setBenefitImagePreview("");
        break;
      default:
        break;
    }
  };

  const nftImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setNftFile(f);
          setNftImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }
      setValidations({ ...initialValidation, picture_nft: "" });
    }
  };

  const floorMapImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setFloorMapFile(f);
          setFloorMapImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }

      setIsFloorMapImageChanged(true);
      setValidations({ ...initialValidation, picture_floormap: "" });
    }
  };

  const smallImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setSmallFile(f);
          setSmallImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }
      setValidations({ ...initialValidation, picture_small: "" });
    }
  };

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleCreate = async () => {
    if (isMobile) {
      if (!(window as any).ethereum) {
        window.location.href = `https://metamask.app.link/dapp/backstage.global/event/eventcard/create/${id}`;
      }
    }
    if (!privacyChecked) {
      addToast("You need to agree to the Terms and Conditions", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (!checkvalidations()) {
      addToast("You need to input correct values for all the inputs", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);

    const added = await client.add(nftFile || "");
    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;

    let _addons = addons;
    let _royalties = royalties;
    let _payees = payees;

    // if (royaltyWallet !== "" && royaltyFee !== "") {
    //   _royalties = [
    //     ...royalties,
    //     {
    //       chain: values.wallet,
    //       wallet: royaltyWallet,
    //       account: royaltyAccount,
    //       fee: royaltyFee,
    //     },
    //   ];
    // }

    const fd = new FormData();
    fd.append("large_image", largeFile as File);
    fd.append("small_image", smallFile as File);
    fd.append("nft_image", nftFile as File);
    fd.append("floormap_image", floorMapFile as File);
    fd.append("multi_video", multiVideo as File);
    for (let index = 0; index < multiImages.length; index++) {
      const multiImage = multiImages[index];
      fd.append(`multi_image${index}`, multiImage as File);
    }
    for (let index = 0; index < benefitImages.length; index++) {
      const element = benefitImages[index];
      fd.append(`benefit_image${index}`, element as File);
    }
    fd.append("picture_ipfs", ipfs_url);
    fd.append("creator", userInfo?.user?.id);
    fd.append("contract_address", USDTPayment_mainnet);
    fd.append("green_pass_needed", greenPass as any);
    fd.append("fixed_period", fixePeriod as any);
    fd.append("tax_include", taxInclude as any);
    fd.append("addons", JSON.stringify(_addons));
    fd.append("seats", "[]");
    fd.append("payees", JSON.stringify(_payees));
    fd.append("royalties", JSON.stringify(_royalties));
    if (id === "633d633f-9a5e-4d9b-a613-3ab440615264") {
      fd.append("NFT_address", "0xC2575EF110C1B8939dd47c0F1bA12e95896761e3");
    }

    for (const [key, value] of Object.entries(values)) {
      if (key === "price") {
        let addonPrice = 0;
        const len = _addons.length;
        for (let i = 0; i < len; i++) {
          addonPrice += Number(_addons[i].price);
        }
        let payeeFee = 0;
        _payees.map((payee) => {
          payeeFee += Number(payee.fee);
          return 0;
        });
        console.log(((Number(value) + addonPrice) * (100 + payeeFee)) / 100);
        fd.append(
          key,
          (((Number(value) + addonPrice) * (100 + payeeFee)) / 100) as any
        );
      } else {
        fd.append(key, value as any);
      }
    }
    createEventCard(fd)
      .then(async (res) => {
        if (res.success) {
          await creatEventOnContract(res.eventcard);
          setLoading(false);
        } else {
          addToast("failed", { appearance: "error", autoDismiss: true });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const updateCategory = async () => {
    if (collection?.categories === null) {
      await updateCollectionCategory({
        id: id as string,
        categories: JSON.stringify([values.category]),
      });
    } else if (
      collection?.categories !== null &&
      JSON.parse(collection?.categories as string).indexOf(values.category) ===
        -1
    ) {
      let categories = JSON.parse(collection?.categories as string);
      categories.push(values.category);
      await updateCollectionCategory({
        id: id as string,
        categories: JSON.stringify(categories),
      });
    }
  };

  async function creatEventOnContract(event: IEventCard) {
    await updateCategory();
    addToast("Successfully Created", {
      appearance: "success",
      autoDismiss: true,
    });
    navigate("/");
  }

  const borderStyle = (str: string) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  useEffect(() => {
    if (
      userInfo?.user?.user_type === "ADMIN" ||
      (userInfo?.user?.user_type === "BUSINESS" &&
        userInfo?.user?.business_allow)
    ) {
      return;
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className="create__item-contain">
      {collection && (
        <div className={styles.main__banner}>
          <img
            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${
              isMobile
                ? collection?.picture_mobile_large
                  ? collection?.picture_mobile_large
                  : collection?.picture_background
                : collection?.picture_large
            }`}
            alt="banner"
          />
        </div>
      )}
      {collection && (
        <div className={styles.detail_container}>
          <div className="row row--grid">
            <div className="col-12 col-xl-4" style={{ padding: 0 }}>
              <div className="collection__author-meta">
                <div className="create__eventCard_collection">
                  <Link to="/author" className="collection__author-avatar">
                    <LazyLoadImage
                      src={`${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_small}`}
                      alt="data"
                    />
                  </Link>
                  <div>
                    <h1 className="collection__author-name">
                      <Link to="#">{collection?.name}</Link>
                      <div className="sellers-list__author--verified" />
                    </h1>
                    <h2 className="collection__author-nickname">
                      <span>by&nbsp;</span>
                      <Link to="#">@{collection?.creator?.name}</Link>
                    </h2>
                  </div>
                </div>
                <div
                  className="collection__description"
                  dangerouslySetInnerHTML={{
                    __html: collection?.description,
                  }}
                ></div>
              </div>
            </div>

            <div
              className="col-12 col-xl-8"
              style={{ paddingRight: 0, paddingLeft: isTablet ? 0 : 30 }}
            >
              <h2 className="cr__evcard--title">{t("add item")}</h2>

              <div>
                <form action="#">
                  <div className="cr__evcard-subItem cr__evcard-subItem-category">
                    <h4 className="cr__evcard--subtitle">
                      <span></span>
                      {t("item category")}
                    </h4>
                    <div className="cr__evcard-rowParent">
                      <div className="sign__group cr__evcard-50">
                        <p className="cr__evcard-labelTitle">
                          {t("select category")}
                        </p>
                        <select
                          id="category"
                          name="category"
                          className="sign__select"
                          style={borderStyle(validations.category)}
                          value={values.category}
                          onChange={(e) =>
                            handleChange("category", e.target.value)
                          }
                        >
                          <option disabled hidden value="">
                            {t("select category")}
                          </option>
                          <option value="Category1">
                            {t("tickets/events")}
                          </option>
                          <option value="Category2">{t("digital arts")}</option>
                          <option value="Category3">{t("services")}</option>
                        </select>
                        <img
                          alt="NFT"
                          className="cr__evcard-selectArrow"
                          src="/img/icons/arrow-down.svg"
                        />

                        {validations.category === "has-empty" && (
                          <span className="text-error">
                            {t("category required")}*
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Upload EvCard Image */}
                  {values.category !== "" && (
                    <div className="cr__evcard-upload-block">
                      <ImageUpload
                        validations={validations}
                        values={values}
                        largeFile={largeFile}
                        largeImageChange={largeImageChange}
                        largeImagePreview={largeImagePreview}
                        largeImageLoaded={largeImageLoaded}
                        smallFile={smallFile}
                        smallImageChange={smallImageChange}
                        smallImagePreview={smallImagePreview}
                        nftFile={nftFile}
                        nftImageChange={nftImageChange}
                        nftImagePreview={nftImagePreview}
                        floorMapFile={floorMapFile}
                        floorMapImageChange={floorMapImageChange}
                        floorMapImagePreview={floorMapImagePreview}
                        imageDelete={imageDelete}
                        multiImageChange={multiImageChange}
                        multiImagesPreview={multiImagesPreview}
                        multiImageDelete={multiImageDelete}
                        multiVideoChange={multiVideoChange}
                        multiVideoPreview={multiVideoPreview}
                      />
                      <ItemDetail
                        validations={validations}
                        values={values}
                        handleChange={handleChange}
                        toggleFixedPeriod={toggleFixedPeriod}
                        toggleTaxInclude={toggleTaxInclude}
                      />
                      <ItemLinks
                        validations={validations}
                        values={values}
                        handleChange={handleChange}
                      />
                      <div className="cr__evcard-100">
                        <div className="sign__group sign__group--checkbox">
                          <input
                            id="type5"
                            name="type5"
                            type="checkbox"
                            defaultChecked
                            onClick={toggleGreenPass}
                          />
                          <label
                            htmlFor="type5"
                            style={{ color: "#fff", letterSpacing: "0.03em" }}
                          >
                            {t("green pass required")}
                          </label>
                        </div>
                      </div>
                      {/* ADD-ON */}
                      <ItemAddons
                        values={values}
                        addons={addons}
                        remove_Addon={remove_Addon}
                        add_Addons={add_Addons}
                        addonIcons={addonIcons}
                        benefitImage={benefitImage}
                        benefitImagePreview={benefitImagePreview}
                        benefitImageChange={benefitImageChange}
                        imageDelete={imageDelete}
                      />
                      <SplitPayment
                        payees={payees}
                        remove_Payee={remove_Payee}
                        add_Payee={add_Payee}
                      />
                      <ItemRoyalty
                        values={values}
                        royalties={royalties}
                        remove_Royalty={remove_Royalty}
                        add_Royalty={add_Royalty}
                        handleChange={handleChange}
                      />
                    </div>
                  )}
                </form>

                {/* {!isMobile && ( */}
                {values.category !== "" && (
                  <div className="sign__group sign__group--checkbox">
                    <input
                      id="_remember"
                      name="_remember"
                      type="checkbox"
                      checked={privacyChecked}
                      onChange={() => {
                        setPrivacyChecked(!privacyChecked);
                      }}
                    />
                    <label htmlFor="_remember">
                      {t("I agree.text1")}
                      <Link to="/terms" target="_blank">
                        {" "}
                        {t("I agree.terms")}
                      </Link>
                    </label>
                  </div>
                )}

                {values.category !== "" && (
                  <div className="sign__group" style={{ padding: "0 30px" }}>
                    <div
                      className="sign__btn"
                      onClick={handleCreate}
                      style={{ marginTop: 30, marginBottom: 200 }}
                    >
                      {t("create item")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageEventCardCreate;
