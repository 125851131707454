import React, { useEffect, useState, useRef, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Link, useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { getArticleById } from "../../../api/article";
import { IArticle } from "../../../utils/interface";
import { dateString } from "../../../utils";
import CustomHelmet from "../../../components/CustomHelmet";
import { updateArticleLike } from "../../../api/article";
import { createShareLinkTrack } from "../../../api/sharelinktrack";
import LinkShare from "../../../components/link_share";
import styles from "./index.module.css";

const PageArticle = () => {
  const { articleId } = useParams();
  const location = useLocation();
  const id = useMemo(() => {
    if (location.pathname.toLowerCase() === "/eventfi") {
      return "f2cd3a16-aae0-43db-a288-54b548042ca2";
    } else if (location.pathname.toLowerCase() === "/article/earasers") {
      return "8370e6e9-0816-4272-9f76-85a61a8de385";
    } else {
      return articleId;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId]);
  const { title } = useParams();
  const [searchParams] = useSearchParams();
  const [article, setArticle] = useState<IArticle>();
  const { loading, setLoading, addToast } = useAppContext();
  const topShareElement = useRef<HTMLButtonElement>(null);
  const bottomShareElement = useRef<HTMLButtonElement>(null);
  const [topShare, setTopShare] = useState(false);
  const [bottomShare, setBottomShare] = useState(false);
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 450px)");

  const updateLikes = () => {
    if (!userInfo) {
      const url = `/article/${title}/${id}`;
      navigate(`/signin?redirectURL=${url}`);
      return;
    }
    let likes =
      article?.likes_number === null
        ? []
        : JSON.parse(article?.likes_number as string);
    const like_index = likes.indexOf(userInfo.user.id);
    if (like_index === -1) {
      likes.push(userInfo.user.id);
    } else {
      likes.splice(like_index, 1);
    }
    console.log(likes);
    updateArticleLike({
      id: id,
      likes_number: JSON.stringify(likes),
    }).then((res) => {
      if (res.success) {
        console.log(res);
        setArticle(res.article);
      }
    });
  };

  useEffect(() => {
    // Return a cleanup function to remove the event listener

    setLoading(true);
    getArticleById(id)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setArticle(res.article);
        } else {
          addToast(res.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (event: any) => {
    if (
      topShareElement.current &&
      !topShareElement.current.contains(event.target)
    ) {
      setTopShare(false);
    }
    if (
      bottomShareElement.current &&
      !bottomShareElement.current.contains(event.target)
    ) {
      setBottomShare(false);
    }
  };

  // Effect hook to attach/detach the global click event listener
  useEffect(() => {
    // Attach the global click event listener
    document.addEventListener("click", handleOutsideClick);

    // Return a cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const createLinkTrack = async () => {
    const trackId = searchParams.get("trackId");
    if (trackId) {
      console.log(trackId);
      const data = {
        trackId,
        url: window.location.href,
        category: "news",
      };
      const res = await createShareLinkTrack(data);
      console.log(res);
    }
  }

  useEffect(() => {
    createLinkTrack();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.article}>
      {!loading && article?.title && (
        <CustomHelmet
          title={article.title}
          description={article.title}
          keywords={article.title}
          image={`${process.env.REACT_APP_ASSET_BASE_URL}/${article?.image}`}
          canonical={window.location.href}
        />
      )}
      {userInfo?.user?.user_type === "ADMIN" && (
        <Link to={`/article/edit/` + id}>
          <p className={styles.edit__article}>{t("edit article")}</p>
        </Link>
      )}
      {isMobile ? (
        <div className="article__top">
          <div onClick={() => navigate("/blog")}>
            <LazyLoadImage src="/img/icons/arrow-left.svg" alt="data" />
          </div>
          <p>{t("article")}</p>
          <div>
            <LazyLoadImage
              src="/img/icons/upload.svg"
              alt="data"
              style={{ display: "none" }}
            />
          </div>
        </div>
      ) : (
        <div className="article__top">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/blog">{t("news")}</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              {t("article")}
            </li>
          </ul>
        </div>
      )}
      {article && (
        <>
          <div className={styles.article__content}>
            <div className={styles.img_wrapper}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_ASSET_BASE_URL}/${article?.image}`}
                alt=""
              />
              <div className={styles.top__article_container}>
                <div className={styles.article__news_group}>
                  <span className={styles.article__date}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z"></path>
                    </svg>{" "}
                    {dateString(article?.createdAt)}
                  </span>
                  {/* <span className={styles.dot}>&#183;</span>
                  <div className={styles.article__message}>
                    <img src="/img/icons/message-square.svg" alt="data" />
                    <p style={{ margin: 0 }}>{article?.read}</p>
                  </div> */}
                  <span className={styles.dot}>&#183;</span>
                  <div className={styles.article__message}>
                    <img src="/img/icons/thumbs-up.svg" alt="data" />
                    <p style={{ margin: 0 }}>
                      {article && article?.likes_number !== null
                        ? JSON.parse(article?.likes_number).length
                        : 0}
                    </p>
                  </div>
                </div>
                <div className={styles.article__like_group}>
                  <button
                    onClick={() => setTopShare(!topShare)}
                    className={styles.share__btn}
                    ref={topShareElement}
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33594 10.8208V17.4875C3.33594 17.9295 3.51153 18.3534 3.82409 18.666C4.13665 18.9785 4.56058 19.1541 5.0026 19.1541H15.0026C15.4446 19.1541 15.8686 18.9785 16.1811 18.666C16.4937 18.3534 16.6693 17.9295 16.6693 17.4875V10.8208"
                        stroke="white"
                        strokeOpacity="0.66"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3307 5.82088L9.9974 2.48755L6.66406 5.82088"
                        stroke="white"
                        strokeOpacity="0.66"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 2.48755V13.3209"
                        stroke="white"
                        strokeOpacity="0.66"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p style={{ margin: "0 0 0 8px" }}>Share</p>
                  </button>
                  <div
                    className={styles.dropdown__share}
                    style={{ display: topShare ? "block" : "none" }}
                  >
                    <LinkShare
                      url={`${process.env.REACT_APP_SITE_URL}/article/${title}/${id}?trackId=${userInfo?.user?.id}`}
                      text={article.title}
                    />
                  </div>
                  <button
                    className={styles.article__like}
                    onClick={updateLikes}
                  >
                    {userInfo &&
                    article &&
                    article?.likes_number &&
                    article?.likes_number.includes(userInfo.user.id) ? (
                      <img src="/img/icons/liked-thumbs-up.svg" alt="data" />
                    ) : (
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.83073 9.98755L9.16406 2.48755C9.8271 2.48755 10.463 2.75094 10.9318 3.21978C11.4007 3.68862 11.6641 4.32451 11.6641 4.98755V8.32088H16.3807C16.6223 8.31815 16.8616 8.36797 17.082 8.4669C17.3025 8.56582 17.4987 8.71149 17.6573 8.89381C17.8158 9.07613 17.9328 9.29073 18.0002 9.52276C18.0675 9.75478 18.0837 9.99868 18.0474 10.2375L16.8974 17.7375C16.8371 18.135 16.6353 18.4972 16.329 18.7576C16.0227 19.0179 15.6327 19.1588 15.2307 19.1542H5.83073M5.83073 9.98755V19.1542M5.83073 9.98755H3.33073C2.8887 9.98755 2.46478 10.1631 2.15222 10.4757C1.83966 10.7883 1.66406 11.2122 1.66406 11.6542V17.4875C1.66406 17.9296 1.83966 18.3535 2.15222 18.6661C2.46478 18.9786 2.8887 19.1542 3.33073 19.1542H5.83073"
                          stroke="white"
                          strokeOpacity="0.66"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    {userInfo &&
                    article &&
                    article?.likes_number &&
                    article?.likes_number.includes(userInfo.user.id) ? (
                      <p style={{ margin: 0, color: "#6A4DFD", wordBreak: 'unset' }}>Like</p>
                    ) : (
                      <p style={{ margin: "0 0 0 8px", wordBreak: 'unset' }}>Like</p>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {!loading && !article && <h1>{t("can't find the article")}</h1>}

            {!loading && article && (
              <>
                <h1 className={styles.article__content_title}>
                  {article.title}
                </h1>
                <div
                  dangerouslySetInnerHTML={{ __html: article.description }}
                />
              </>
            )}
          </div>
          <div className={styles.article__container}>
            <div className={styles.article__news_group}>
              <span className={styles.article__category}>{t("news")}</span>
            </div>
            <div className={styles.article__like_group}>
              <button
                onClick={() => setBottomShare(!bottomShare)}
                className={styles.share__btn}
                ref={bottomShareElement}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33594 10.8208V17.4875C3.33594 17.9295 3.51153 18.3534 3.82409 18.666C4.13665 18.9785 4.56058 19.1541 5.0026 19.1541H15.0026C15.4446 19.1541 15.8686 18.9785 16.1811 18.666C16.4937 18.3534 16.6693 17.9295 16.6693 17.4875V10.8208"
                    stroke="white"
                    strokeOpacity="0.66"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.3307 5.82088L9.9974 2.48755L6.66406 5.82088"
                    stroke="white"
                    strokeOpacity="0.66"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 2.48755V13.3209"
                    stroke="white"
                    strokeOpacity="0.66"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p style={{ margin: "0 0 0 8px" }}>Share</p>
              </button>
              <div
                className={styles.dropdown__share_bottom}
                style={{ display: bottomShare ? "block" : "none" }}
              >
                <LinkShare
                  url={`${process.env.REACT_APP_SITE_URL}/article/${title}/${id}?trackId=${userInfo?.user?.id}`}
                  text={article.title}
                />
              </div>
              <button className={styles.article__like} onClick={updateLikes}>
                {userInfo &&
                article &&
                article?.likes_number &&
                article?.likes_number.includes(userInfo.user.id) ? (
                  <img src="/img/icons/liked-thumbs-up.svg" alt="data" />
                ) : (
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.83073 9.98755L9.16406 2.48755C9.8271 2.48755 10.463 2.75094 10.9318 3.21978C11.4007 3.68862 11.6641 4.32451 11.6641 4.98755V8.32088H16.3807C16.6223 8.31815 16.8616 8.36797 17.082 8.4669C17.3025 8.56582 17.4987 8.71149 17.6573 8.89381C17.8158 9.07613 17.9328 9.29073 18.0002 9.52276C18.0675 9.75478 18.0837 9.99868 18.0474 10.2375L16.8974 17.7375C16.8371 18.135 16.6353 18.4972 16.329 18.7576C16.0227 19.0179 15.6327 19.1588 15.2307 19.1542H5.83073M5.83073 9.98755V19.1542M5.83073 9.98755H3.33073C2.8887 9.98755 2.46478 10.1631 2.15222 10.4757C1.83966 10.7883 1.66406 11.2122 1.66406 11.6542V17.4875C1.66406 17.9296 1.83966 18.3535 2.15222 18.6661C2.46478 18.9786 2.8887 19.1542 3.33073 19.1542H5.83073"
                      stroke="white"
                      strokeOpacity="0.66"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {userInfo &&
                article &&
                article?.likes_number &&
                article?.likes_number.includes(userInfo.user.id) ? (
                  <p style={{ margin: 0, color: "#6A4DFD" }}>Like</p>
                ) : (
                  <p style={{ margin: "0 0 0 8px" }}>Like</p>
                )}
              </button>
            </div>
          </div>
        </>
      )}
      <Link to="/blog">
        <div className={styles.article__back_block}>
          <div>
            <img
              src="/img/arrow-left-sm.png"
              className={styles.article__img}
              alt="data"
            />
          </div>
          <p className={styles.article__back_text}>{t("Back to News")}</p>
        </div>
      </Link>
      <div className="bottom_block"></div>
    </div>
  );
};

export default PageArticle;
