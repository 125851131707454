import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.early__bird_home}>
      <Link
        to={`collection/${process.env.REACT_APP_HERO_1}`}
        className={styles.early__bird_item}
      >
        <img
          className={styles.early__bird_img}
          alt="chile"
          src="/img/hero/nightday.jpg"
        />
        <div className={styles.early__bird_bottom}>
          <p className={styles.early__bird_text}>
            <span className={styles.early__bird_date}>{t("buy")} →</span>
          </p>
        </div>
      </Link>
      <Link
        to={`/collection/${process.env.REACT_APP_HERO_2}`}
        className={styles.early__bird_item}
      >
        <img
          className={styles.early__bird_img}
          alt="Ebler"
          src="/img/hero/chile_mobile.png"
        />
        <div className={styles.early__bird_bottom}>
          <p className={styles.early__bird_text}>
            <span className={styles.early__bird_date}>{t("buy")} →</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default Hero;
