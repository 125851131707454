/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import {
  Link,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import {
  deleteEventCardById,
  getCollectionById,
  getEventCardInCollection,
  updateEventLike,
} from "../../../api/event";
import { ICollection, IEventCard } from "../../../utils/interface";
import { permissionFilter } from "../../../utils/permission";
import Tile from "../../../components/tile";
import Pagination from "../../../components/pagination";
import Search from "../../../components/search";
import CollectionFilterSort from "../../../components/CollectionFilterSort";
import CustomHelmet from "../../../components/CustomHelmet";
import { createShareLinkTrack } from "../../../api/sharelinktrack";
import styles from "./index.module.css";

const PageCollection = () => {
  const { id } = useParams();
  const location = useLocation();
  const {
    setLoading,
    cPageNumber,
    setCPageNumber,
    permission,
  } = useAppContext();
  const [eventCards, setEventCards] = useState<IEventCard[]>(
    [] as IEventCard[]
  );
  const [allEvents, setAllEvents] = useState<IEventCard[]>([] as IEventCard[]);
  const [collection, setCollection] = useState<ICollection>({} as ICollection);
  const [collectionId, setCollectionId] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [itemIndex, setItemIndex] = useState(cPageNumber);
  const [ownerCnt, setOwnerCnt] = useState(0);
  const { userInfo } = useUserContext();
  const [currentTag, setCurrentTag] = useState("all");
  const [currentBtn, setCurrentBtn] = useState("");
  const [sort, setSort] = useState(4);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(8);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 628px)");
  const isMiddle = useMediaQuery("(max-width: 1256px)");
  const isSmall = useMediaQuery("(max-width: 992px)");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const keywords = useMemo(
    () => allEvents.map((item: IEventCard) => item.name).join(", "),
    [allEvents]
  );

  useEffect(() => {
    if (isMobile) {
      setPageCount(4);
    } else {
      setPageCount(8);
    }
  }, [isMiddle, isSmall, isMobile]);

  const leftClick = () => {
    if (itemIndex > 0) {
      let _itemIndex = itemIndex;
      _itemIndex--;
      setItemIndex(_itemIndex);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * pageCount < eventCards.length) {
      let _itemIndex = itemIndex;
      _itemIndex++;
      setItemIndex(_itemIndex);
    }
  };

  useEffect(() => {
    setCPageNumber(itemIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex]);

  useEffect(() => {
    setLoading(true);
    const _id =
      id ||
      (location.pathname.toLowerCase() === "/ha-ash"
        ? "572b2ae7-a049-4a99-81c3-e38e935bf166"
        : location.pathname.toLowerCase() === "/hayafest"
        ? "b4f96ec6-3de6-48aa-964d-11b0a974e5e1"
        : location.pathname.toLowerCase() === "/matute"
        ? "4a6412bd-96be-4524-9f00-529edae0ce22"
        : location.pathname.toLowerCase() === "/imolese"
        ? "5b91ac0c-3eb6-45f9-b42d-f07bbd6de25d"
        : location.pathname.toLowerCase() === "/theboatparty"
        ? "57566b84-ea5c-4410-b1f4-3426faa3ede3"
        : location.pathname.toLowerCase() === "/santoremedio"
        ? "633d633f-9a5e-4d9b-a613-3ab440615264"
        : location.pathname.toLowerCase() === "/earasers"
        ? "9ed03979-12bd-4c87-addf-23bdbd587c85"
        : location.pathname.toLowerCase() === "/nightday"
        ? "d7d9eed4-eb48-415d-8bec-044cf0ac8b2f"
        : location.pathname.toLowerCase() === "/zamna"
        ? "36db53e3-e8eb-450f-960d-6bc619526a19"
        : location.pathname.toLowerCase() === "/zamnafrance"
        ? "124b2f6c-02ff-4a02-8637-8e9cffd9a191"
        : location.pathname.toLowerCase() === "/onparty"
        ? "ef2f3ee5-4e7c-4f08-8bd6-40eefded2401"
        : location.pathname.toLowerCase() === "/doit4music"
        ? "733a0446-dc2e-4efd-94e2-4705c5caa5b1"
        : location.pathname.toLowerCase() === "/bamboleo"
        ? "12e47625-276d-421e-8877-73b747343504"
        : "");
    console.log(_id);
    setCollectionId(_id);
    getCollectionById(_id).then((res) => {
      if (res.success) {
        if (res.collection.deleted) {
          navigate("/");
        }
        setCollection(res.collection);
      }
    });
    getEventCardInCollection(_id).then((res) => {
      if (res.success) {
        let temp: never[] = [];
        temp = res.eventcards.sort(
          (a: IEventCard, b: IEventCard) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setAllEvents([...temp]);
        sortEvents([...temp]);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const creators: string[] = [];
    eventCards.map((eventcard: IEventCard) => {
      if (!creators.includes(eventcard.creator.id))
        creators.push(eventcard.creator.id);
      return 0;
    });
    setOwnerCnt(creators.length);
  }, [eventCards]);

  const handleDeleteEventCard = (eventCardId: string) => {
    deleteEventCardById(eventCardId)
      .then((res) => {
        const updatedEvents = [...eventCards].filter(
          (eventcard: IEventCard) => eventcard.id !== eventCardId
        );
        setEventCards(updatedEvents);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLikesNumber: (eventCard: any) => number = (eventCard: any) => {
    let likes = [];
    try {
      likes = JSON.parse(eventCard.likes_number);
    } catch (err) {
      likes = [];
    }
    if (typeof likes !== "object" || likes === null) likes = [];
    if (likes) return likes.length;
    else return 0;
  };

  const sortEvents = (events: IEventCard[]) => {
    let _temp: any[] = [];
    if (currentTag === "all") {
      _temp = [...events];
    } else {
      _temp = events.filter((event: any) => event.category === currentTag);
    }
    let temp: any[] = [];
    if (sort === 0) {
      temp = _temp.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (sort === 1) {
      temp = _temp.sort((a: any, b: any) => Number(a.price) - Number(b.price));
    } else if (sort === 2) {
      temp = _temp.sort((a: any, b: any) => Number(b.price) - Number(a.price));
    } else if (sort === 3) {
      temp = _temp.sort(
        (a: any, b: any) => getLikesNumber(b) - getLikesNumber(a)
      );
    } else if (sort === 4) {
      temp = _temp.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }
    let _res = _temp.filter((event) =>
      search === ""
        ? event
        : event.name.toLowerCase().includes(search.toLowerCase())
    );
    let soldTickets = [];
    let saleTickets = [];
    for (let index = 0; index < _res.length; index++) {
      const element = _res[index];
      const currentTime = new Date();
      const timeZoneDiff =
        (currentTime.getTimezoneOffset() / 60 + element.time_zone) *
        3600 *
        1000;
      if (
        currentTime.getTime() + timeZoneDiff >
        new Date(element.stop_time).getTime()
      ) {
        soldTickets.push(element);
      } else {
        saleTickets.push(element);
      }
    }
    setEventCards([...saleTickets, ...soldTickets]);
  };

  const createLinkTrack = async () => {
    const trackId = searchParams.get("trackId");
    if (trackId) {
      console.log(trackId);
      const data = {
        trackId,
        url: window.location.href,
        category: "collection",
      };
      const res = await createShareLinkTrack(data);
      console.log(res);
    }
  };

  useEffect(() => {
    createLinkTrack();
    setItemIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortEvents(allEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentTag, sort]);

  return (
    <div className={styles.collection__contain_block}>
      {collection && (
        <CustomHelmet
          title={collection.name}
          description={collection.description}
          keywords={keywords}
          image={`${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_large}`}
          canonical={window.location.href}
        />
      )}
      {collection?.picture_background ? (
        <>
          <div className={styles.main__banner}>
            <img
              src={`${process.env.REACT_APP_ASSET_BASE_URL}/${
                isMobile
                  ? collection?.picture_mobile_large
                    ? collection?.picture_mobile_large
                    : collection.picture_background
                  : collection?.picture_large
              }`}
              alt="banner"
            />
          </div>
          <div
            className="container"
            style={{
              padding: isMiddle ? "0px 16px" : 0,
              maxWidth: 1224,
            }}
          >
            <div
              className={isMobile ? " " : "row row--grid"}
              style={{ width: "100%", marginLeft: 0 }}
            >
              <div className="col-12 col-xl-12" style={{ padding: 0 }}>
                <div className="collection__author-meta">
                  <div className="collection__avatar-container">
                    {collection?.picture_small && (
                      <Link to="#" className="collection__author-avatar">
                        <div>
                          <img
                            alt=""
                            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_small}`}
                          />
                        </div>
                      </Link>
                    )}
                    <div className="collection__avatar-name">
                      {collection.name && (
                        <h1 className="collection__author-name">
                          <Link to="#">{collection?.name}</Link>
                          <div
                            className="sellers-list__author--verified"
                            style={{ marginLeft: 4 }}
                          />
                        </h1>
                      )}
                      {collection?.creator?.name && (
                        <h2 className="collection__author-nickname">
                          <span>by&nbsp;</span>
                          <Link to="#">@{collection?.creator?.name}</Link>
                        </h2>
                      )}
                    </div>
                  </div>
                  <div
                    className="collection__author-description"
                    dangerouslySetInnerHTML={{
                      __html: collection?.description,
                    }}
                  />
                </div>
                {userInfo &&
                  !isMobile &&
                  (userInfo.user.user_type === "ADMIN" ||
                    (userInfo.user.user_type === "BUSINESS" &&
                      userInfo.user.id === collection?.creator?.id)) && (
                    <div className="collection__btn_container">
                      <Link to={`/event/eventcard/create/${collection?.id}`}>
                        <button
                          type="button"
                          disabled={collection.royalty_wallet === ""}
                          className="sign__btn collection__btn"
                        >
                          {t("create item")}
                        </button>
                      </Link>
                      <Link
                        to={`/generate_link?origin=${window.location.href}`}
                      >
                        <button
                          type="button"
                          className="sign__btn collection__btn"
                        >
                          {t("generate links")}
                        </button>
                      </Link>
                    </div>
                  )}
              </div>
              <div
                className="collection__filter_block"
                style={{
                  marginTop: userInfo?.user?.user_type === "ADMIN" ? 20 : 44,
                }}
              >
                <Search
                  onChange={setSearch}
                  placeholder={t("search the item")}
                />
                <CollectionFilterSort
                  currentBtn={currentBtn}
                  currentTag={currentTag}
                  sort={sort}
                  setCurrentBtn={setCurrentBtn}
                  setCurrentTag={setCurrentTag}
                  setSort={setSort}
                />
              </div>

              <div style={{ width: "100%" }}>
                <div
                  className={styles.coll__grid_container}
                  style={{ margin: isMiddle ? "auto" : 0 }}
                >
                  {eventCards.map(
                    (eventCard: IEventCard, i) =>
                      i >= (pageIndex + itemIndex) * pageCount &&
                      i < (pageIndex + itemIndex + 1) * pageCount &&
                      permissionFilter(eventCard, permission) && (
                        <div key={`explorer_event_${i}`}>
                          <Tile
                            eventCard={eventCard}
                            i={i}
                            carousel={true}
                            deleteEventCard={handleDeleteEventCard}
                          />
                        </div>
                      )
                  )}
                </div>
                <Pagination
                  items={eventCards}
                  setItemIndex={setItemIndex}
                  pageIndex={pageIndex}
                  itemIndex={itemIndex}
                  leftClick={leftClick}
                  rightClick={rightClick}
                  count={pageCount}
                />
              </div>
            </div>
            <div className="bottom_block"></div>
          </div>
        </>
      ) : (
        <div className={styles.empty__block}></div>
      )}
    </div>
  );
};

export default PageCollection;
