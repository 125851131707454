/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { useUserContext } from "../../../context/UserContext";
import styles from "./index.module.css";

const PageGenerateLink = () => {
  const { userInfo } = useUserContext();
  const [custom, setCustom] = useState("");
  const [searchParams] = useSearchParams();
  const [validation, setValidation] = useState(true);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const exportFile = (media: string) => {
    const origin = searchParams.get("origin");
    let referral_links = "";
    referral_links += origin + "?media=" + media;

    var blob = new Blob([referral_links], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "referral_links.txt");
  };

  const exportTrackFile = (trackId: string) => {
    const origin = searchParams.get("origin");
    let referral_links = "";
    referral_links += origin + "?trackId=" + trackId;

    var blob = new Blob([referral_links], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "referral_links.txt");
  };

  const generateCustomLink = () => {
    if (custom !== "") {
      exportTrackFile(custom.replaceAll(" ", "_"));
    } else {
      setValidation(false);
    }
  };

  const borderStyle = (validation: boolean) => {
    return !validation
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  useEffect(() => {
    if (
      userInfo?.user?.user_type === "ADMIN" ||
      (userInfo?.user?.user_type === "BUSINESS" &&
        userInfo?.user?.business_allow)
    ) {
      return;
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("generate referral links")}</h2>

      <div style={{ paddingLeft: 0 }}>
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("facebook")}
        >
          Facebook Link
        </button>
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("instagram")}
        >
          Instagram Link
        </button>
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("X")}
        >
          X Link
        </button>
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("youtube")}
        >
          Youtube Link
        </button>
        <div style={{ marginTop: 30 }}>
          <input
            id="name"
            type="text"
            name="name"
            className={styles.input}
            value={custom}
            onChange={(e) => {
              setValidation(true);
              setCustom(e.target.value);
            }}
            style={borderStyle(validation)}
            placeholder="Please input custom trackId"
          />
          {!validation && <p className={styles.error_text}>{t("trackId is required")} *</p>}
          <button className={styles.generate_btn} onClick={generateCustomLink}>
            Custom Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageGenerateLink;
